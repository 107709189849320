import React from 'react'

export default function Gallery() {
  return (
    <div>
      <div className="banner-gallery">

        <div className="container">

            <div className="banner-content">

                <h2>Our Gallery</h2>

            </div>

        </div>

      </div>
    </div>
  )
}
