import React, { useState, useEffect } from 'react';

export default function CounterSection() {
  const [counters, setCounters] = useState({
    happyClients: 0,
    corporateEvents: 0,
    wedding: 0,
    musicalConcerts: 0,
  });

  useEffect(() => {
    const targetValues = {
      happyClients: 66,
      corporateEvents: 58,
      wedding: 33,
      musicalConcerts: 56,
    };

    const interval = setInterval(() => {
      setCounters(prevCounters => {
        const updatedCounters = {};
        let allCountersReachedTarget = true;

        for (const key in prevCounters) {
          if (prevCounters[key] < targetValues[key]) {
            updatedCounters[key] = prevCounters[key] + 1;
            allCountersReachedTarget = false;
          } else {
            updatedCounters[key] = targetValues[key];
          }
        }

        if (allCountersReachedTarget) {
          clearInterval(interval);
        }

        return updatedCounters;
      });
    }, 50); // Adjust the interval speed as needed (in milliseconds)

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <section className="counters123">
        <div className="fun-factor-area fun-bg mb-95 mt-80">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-6 col-sm-6">
                <div className="single-funfactor mb-30 text-center">
                  <div className="icon">
                  <i className="fa-solid fa-user fa-2xs"></i>
                  </div>

                  <div className="fun-facttor-number">
                    <h2>
                      <span className="counter">{counters.happyClients}</span>+
                    </h2>
                  </div>

                  <h4 className="counter-title">Happy Clients</h4>
                </div>
              </div>
              <div className="col-md-3 col-6 col-sm-6">
                <div className="single-funfactor mb-30 text-center">
                  <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="34" fill="#fff" className="bi bi-people-fill my-2" viewBox="0 0 16 16">
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
                    </svg>
                  </div>

                  <div className="fun-facttor-number">
                    <h2>
                      <span className="counter">{counters.corporateEvents}</span>+
                    </h2>
                  </div>

                  <h4 className="counter-title">Corporate Events</h4>
                </div>
              </div>
              <div className="col-md-3 col-6 col-sm-6">
                <div className="single-funfactor mb-30 text-center">
                  <div className="icon">
                  <i className="fa-solid fa-star fa-2xs"></i>
                  </div>

                  <div className="fun-facttor-number">
                    <h2>
                      <span className="counter">{counters.wedding}</span>+
                    </h2>
                  </div>

                  <h4 className="counter-title">Wedding</h4>
                </div>
              </div>
              <div className="col-md-3 col-6 col-sm-6">
                <div className="single-funfactor mb-30 text-center">
                  <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="34" fill="#fff" className="bi bi-music-note-list my-2" viewBox="0 0 16 16">
                    <path d="M12 13c0 1.105-1.12 2-2.5 2S7 14.105 7 13s1.12-2 2.5-2 2.5.895 2.5 2"/>
                    <path fill-rule="evenodd" d="M12 3v10h-1V3z"/>
                    <path d="M11 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 16 2.22V4l-5 1z"/>
                    <path fill-rule="evenodd" d="M0 11.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5m0-4A.5.5 0 0 1 .5 7H8a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5m0-4A.5.5 0 0 1 .5 3H8a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5"/>
                    </svg>
                  </div>

                  <div className="fun-facttor-number">
                    <h2>
                      <span className="counter">{counters.musicalConcerts}</span>+
                    </h2>
                  </div>

                  <h4 className="counter-title">Musical Concerts</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
