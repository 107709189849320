import React from "react";
import { Link } from "react-router-dom";

export default function Navbar(props) {
    return (
        <>
            <nav className="navbar navbar-expand-lg sticky-top">
                
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <div className="header-logo">
                                <a href="/" aria-label="KBK Events" title="KBK Events">
                                    <img src="https://kbkevents.in/images/kbkevents.png" alt="KBK Events Hyderabad" />
                                </a>
                            </div>
                        </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        
                        <div className="col-md-8 collapse navbar-collapse header-menu-area" id="navbarSupportedContent">
                           
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 main-menu">
                                            <li className="menu11">
                                                <span className="nav-link">
                                                    <a href="/">Home</a>
                                                </span>
                                            </li>

                                            <li className="menu12">
                                                <span className="nav-link">
                                                    <a href="/about">About</a>
                                                </span>
                                            </li>

                                            <li className="menu13">
                                                <span className="nav-link">
                                                    <a href="/event">Events</a>
                                                </span>
                                            </li>

                                            <li className="menu14">
                                                <span className="nav-link">
                                                    <a href="/banquets">Banquets</a>
                                                </span>
                                            </li>

                                            <li className="menu15">
                                                <span className="nav-link">
                                                    <a href="/catering">Catering</a>
                                                </span>
                                            </li>

                                            <li className="menu16 m-none">
                                            <span className="nav-link">
                                            <a href="/vegfood" data-bs-toggle="dropdown">
                                                    Menu
                                                </a>
                                                </span>
                                                
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <span className="nav-link">
                                                            <a href="/veg-food" className="food">
                                                                Veg Menu
                                                            </a>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="nav-link">
                                                            <a href="/non-veg-food" className="food">
                                                                Non-Veg Menu
                                                            </a>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </li>
                                            
                                            <li className="menu15 des-none">
                                                <span className="nav-link">
                                                    <a href="/veg-food">Veg Menu</a>
                                                </span>
                                            </li>
                                            <li className="menu15 des-none">
                                                <span className="nav-link">
                                                    <a href="/non-veg-food">Non-Veg Menu</a>
                                                </span>
                                            </li>
                                            

                                            <li className="menu17">
                                                <span className="nav-link">
                                                    <a href="/gallery">Gallery</a>
                                                </span>
                                            </li>

                                            <li className="menu18">
                                                <span className="nav-link">
                                                    <a href="/contact-us">Contact Us</a>
                                                </span>
                                            </li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <div className="book-now-btn text-right">
                                <span className="nav-link">
                                    <a href="/contact-us">Book Now</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
           
        </>
    );
}
