import React, { useState } from 'react';

const Gallery = () => {
  const [filterValue, setFilterValue] = useState('*');

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  // Sample gallery items data - replace this with your actual data
  const galleryItems = [
    {
      category: 'Wedding',
      imageSrc: 'https://kbkevents.in/images/gallery/wed.jpg',
      alt: 'Wedding Image',
      className: 'gallery__item cat--1',
    },
    {
      category: 'Wedding',
      imageSrc: 'https://kbkevents.in/images/wedding.webp',
      alt: 'Wedding Image',
      className: 'gallery__item cat--1',
    },
    {
      category: 'Engagement',
      imageSrc: 'https://kbkevents.in/images/enga.webp',
      alt: 'Engagement Image',
      className: 'gallery__item cat--2',
    },
    {
      category: 'Anniversary',
      imageSrc: 'https://kbkevents.in/images/gallery/anni.jpg',
      alt: 'Anniversary Image',
      className: 'gallery__item cat--3',
    },
    {
      category: 'Anniversary',
      imageSrc: 'https://kbkevents.in/images/service2.webp',
      alt: 'Anniversary Image',
      className: 'gallery__item cat--3',
    },
    {
      category: 'Sangeet',
      imageSrc: 'http://kbkevents.in/images/sangeet.webp',
      alt: 'Sangeet Image',
      className: 'gallery__item cat--4',
    },
    {
      category: 'Sangeet',
      imageSrc: 'http://kbkevents.in/images/gallery/sangeet-2.jpg',
      alt: 'Sangeet Image',
      className: 'gallery__item cat--4',
    },
    {
      category: 'Corporate Events',
      imageSrc: 'http://kbkevents.in/images/service4.avif',
      alt: 'Corporate Events Image',
      className: 'gallery__item cat--5',
    },
    
    // Add other gallery items with similar structure for other categories
  ];

  return (
    <div className="our-gallery-area pb-95 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center">
              <span>We Are “KBK Events”</span>
              <h3>our gallery</h3>
              <p>
                KBK Events has successfully completed numerous events, catering to a variety of clients. Look into our gallery for inspiration and
                ideas for your next event!
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="hp__portfolio__area gallery__masonry__activation ptb--110 text-center">
              <div className="gallery__menu">
                <button onClick={() => handleFilter('*')} className={filterValue === '*' ? 'is-checked' : ''}>
                  ALL
                </button>
                <button onClick={() => handleFilter('.cat--1')} className={filterValue === '.cat--1' ? 'is-checked' : ''}>
                  Wedding
                </button>
                <button onClick={() => handleFilter('.cat--2')} className={filterValue === '.cat--2' ? 'is-checked' : ''}>
                Engagement
                </button>
                <button onClick={() => handleFilter('.cat--3')} className={filterValue === '.cat--3' ? 'is-checked' : ''}>
                Anniversary
                </button>
                <button onClick={() => handleFilter('.cat--4')} className={filterValue === '.cat--4' ? 'is-checked' : ''}>
                Sangeet
                </button>
                <button onClick={() => handleFilter('.cat--5')} className={filterValue === '.cat--5' ? 'is-checked' : ''}>
                Corporate Events
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row masonry__wrap g-0">
          {galleryItems.map((item, index) => {
            if (filterValue === '*' || item.className.includes(filterValue.replace('.', ''))) {
              return (
                <div key={index} className="col-md-3">
                  <div className="portfoloi">
                    <div className="portfoloi-content">
                      <div className="portfoloi__inner">
                        <h2>
                          <a href="/event">{item.category}</a>
                        </h2>
                        <p>KBK Events</p>
                      </div>
                    </div>
                    <img loading="lazy" src={item.imageSrc} alt={item.alt} />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
