import React from 'react'

export default function VegFood() {
  return (
    <div>
      <div className="veg-menu-banner">

        <div className="container">

            <div className="banner-content">

                <h2>VEG MENU</h2>
                <h6 className="banner-text1 text-white p-2"><i className="fa fa-home"></i> Home <i className="fa fa-chevron-right"></i> <span className="text-danger">Standard Veg Menu</span></h6>

            </div>

        </div>

        </div>
        <div className="section menu-section">

<div className="container menu-item">

    <div className="row menu11">

        <div className="col-lg-3" id="sidebar">
            <div className="sidebar-content">
                <h2 className="h2 sign">Standard</h2>
                <h2 className="h2 sign">Veg Menu</h2>
                <p className="">By: KBK Events</p>
            </div>

            
        </div>

        <div className="col-lg-9">
            <h2 className="text-white">Exclusive Vegetarian Menu South Indian & North Indian</h2>
            <div className="menu-details">
                
                <div id="meneee" className="row my-3">
                    <div className="col-lg-12">
                        <h4>Standard Menu @ 400+ Taxes </h4>
                    </div>
                    <div className="col-md-4">                                    
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 WELCOME DRINK</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VARIETIES OF ROTI</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VEG FRY</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 VEG CURRIES</li>                                        
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group"> 
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 DAL</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VARIETY OF RICE</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 SWEET </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 ICE CREAM / VANILA</li>                                        
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" src="https://kbkevents.in/images/menu/veg-thali.png" alt="Standard Menu @ 400+ Taxes " className="img-fluid"/>
                    </div>
                    
                </div>
                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Silver @ 450+ Taxes </h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 WELCOME DRINK</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 SNACK </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VARIETIES OF ROTI</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  DAL CURRY </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  PANEER CURRY </li>
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  S.I. WET CURRIES </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  DAL  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  VARIETY OF RICE </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  SWEET  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 LIVE ITEM</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MINIMUM 100 PACKS</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" src="https://kbkevents.in/images/menu/st-menu.png" alt="Silver @ 450+ Taxes" className="img-fluid"/>
                    </div>
                    
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Gold @ 550+ Taxes</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 WELCOME DRINK</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 SALADS</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 SNACKS </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 VARIETIES OF ROTI</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 PANEER CURRY</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 N.I VEG CURRIES</li>                                        
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 DAL</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VARIETY OF RICE</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 SWEET</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 ICE CREAM</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 SPL. CHINESE</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" src="https://kbkevents.in/images/menu/gd-menu.png" alt="Gold @ 550+ Taxes" className="img-fluid"/>
                    </div>
                    
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Welcome Drink</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;FRESH FRUIT JUICE </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PINEAPPLE, GRAPE</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SEASONAL FRUITS</li>                                        
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;WATERMELON, MIXED JUICE</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ASSORTED FRUIT JUICES</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="Welcome Drink" src="https://kbkevents.in/images/menu/w-drink.png" className="img-fluid"/>
                    </div>
                    
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Soup</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TOMATO </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CREAM OF VEG </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG HOT &amp; SOUR  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG CORN </li>                                    
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG MANCHOW </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TOMATO DHANIA SHORBA </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL SHORBA </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="Soup" src="https://kbkevents.in/images/menu/soup.png" className="img-fluid"/>
                    </div>
                    
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Salad & Chat</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GREEN SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RUSSIAN SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SPROUTED SALAD </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PASTA SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;YOGUT SALAD  </li>
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RAJMA SALAD </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KACHUMBER SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHANNA CHAT  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO CHANNA CHAT </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PAPADI CHA  </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="Salad and Chat" src="https://kbkevents.in/images/menu/salad.png" className="img-fluid"/>
                    </div>
                    
                </div>

                

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Veg Starter</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SOUTH INDIAN (S.I)</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO BAJJI</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MIRCHI BAJJI </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PLANTAIN BAJJI</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ONION PAKODA</li>
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PALAK PAKODA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CABBAGE PAKODA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG BONDA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MASALA VADA</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="Veg Starter" src="https://kbkevents.in/images/menu/vg-starters1.png" className="img-fluid"/>
                    </div>
                    
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>North Indian</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG MANCHURIAN</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GOBI MANCHURIAN</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GOBI 65</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CRISPY VEGETABLES</li>
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SHANGHAI ROLLS</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SPRING ROLLS</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CRISPY POTATO</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian" src="https://kbkevents.in/images/menu/veg-manchu.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Rotie</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TANDOORI ROTI </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PLAIN NAAN</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BUTTER NAAN</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RUMALI ROTI</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PAROTA</li>                                        
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PULKA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO PAROTA </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG PAROTA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PURI/AJWANI PURI(S.I.) </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="Rotie" src="https://kbkevents.in/images/menu/roti.png" className="img-fluid"/>
                    </div>
                </div>

                

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Dry Veg Curry</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">   
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO FRY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KANDA FRY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DONDA FRY </li>  
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BAIGAN FRY</li>
                                  
                            
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PLANAIN FRY  </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BHENDI FRY </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CABBAGE FRY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CARROT &amp; BEENS FRY  </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="Dry Veg Curry" src="https://kbkevents.in/images/menu/dry-veg.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Wet Veg Curry</h4>
                    </div>
                    <div className="col-md-4">
                          <ul className="list-group">
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SOUTH INDIAN</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG KURMA</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GUTTI VANKAYA CURRY</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DONDAKAYA CURRY</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BENDAKAYA CURRY</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CABBAGE PAKODA</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHANNA MASALA</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;NORTH INDIAN</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG JALFREZI</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MALAI KOFTA</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO PALAK</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;STUFFED CAPSICUM</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;STUFFED TOMATO</li>
                                                                       

                        </ul> 
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO TOMATO CURRY</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO SAG</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO CAPSICUM</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO GOBI MUTTER</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO MUTTER</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MIRCHI SALAN</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO GOBI MUTTER</li>
                              <li className="list-group-item "><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BAGAR BAIGAN</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KADI VEGETABLES</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO MUTTER</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;METHI MALAI CURRY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MIXED VEG CURRY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG KURMA</li>  
                        </ul>
                    </div>

                    <div className="col-md-4">
                        <img loading="lazy" alt="Wet Veg Curry" src="https://kbkevents.in/images/menu/wet-veg-curry.png" className="img-fluid"/>
                    </div>
                </div>

               

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Paneer Curry</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                      

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PANEER BUTTER MASALA</li>
                 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTER PANEER</li>

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KADAI PANEER</li>

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PANEER CHATPATA </li>

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;METHI PANEER </li>

                          <li className="list-group-item "><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PANEER MIRCHI MASALA</li> 

                                                               

                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PALAK PANEER</li> 
                 
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PANEER DO PYAZA</li> 

                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; PANEER SHAI KURMA</li>

                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PANEER TIKKA MASALA </li>

                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PANEER MAKLAN</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy"  alt="Paneer Curry" src="https://kbkevents.in/images/menu/paneer-curry.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Daal</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">

                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SOUTH INDIAN</li> 

                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TOMATO PAPPU </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DONDA FRY </li>  
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GONGURA PAPPU</li> 
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DOSAKAYA PAPPU</li> 
                            
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BEERAKAYA PAPPU  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SORAKAYA PAPPU</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PALAKURA PAPPU</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MANGO DAL</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SEASONAL DAL</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="Daal" src="https://kbkevents.in/images/menu/daal.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>North Indian</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL FRY</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL MAKHANI</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RAJMA DAL</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL MAHARANI</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL MASKA</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL TADKA </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;YELLOW DAL</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RICE &amp; NOODLES </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GONGURA RICE</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;COCONUT RICE</li> 
                      </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BISIBELE BATH</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG BIRYANI</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG FRIED RICE </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG CORN PULAV </li>                                      
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG PULAV</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GREEN PEAS PULAV </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;JEERA RICE </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG DUM BIRYANI  </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG SOFT NOODLES</li>                                      
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG SWZWAN NOODLES </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/veg-rice.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Sweet</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SOUTH INDIAN</li>

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RAVA KESARI</li>

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BOONDI LADDU</li>

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MOONG DAL HALWA</li>

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GAJAR KA HALWA </li>                                      

                      </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">
                            

                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;JELEBI</li>


                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SEMIYA PAYASAM</li> 


                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GULAB JAMUN </li>


                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KAODU KA KHEER</li> 
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="Sweets" src="https://kbkevents.in/images/menu/jalebi.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>North Indian Sweet</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ANGUUR DHANA </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DOUBLE KA MEETHA </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;QUARBANI KA MEETHA</li>
                      </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KALA JAMOON</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GULAB JAMUN</li>                                          
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Sweets" src="https://kbkevents.in/images/menu/north-indain-sweet.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Ice Cream</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VANILLA (S.I)</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;STRAWBERRY(S.I)</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PISTA</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MANGO (S.I)</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BUTTER SCOTCH</li> 
                          
                        </ul>    
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TUTTI FRUITY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHOCOLATE</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ANY SPECIAL ICE CREAM</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;WILL BE ARRANGED</li>
                              
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="Ice Cream" src="https://kbkevents.in/images/menu/ice-cream.png" className="img-fluid" />
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Live items</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PLAIN DOSA </li>

                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ONION DOSA </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MASALA DOSA</li> 
                     
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SET DOSA </li>
                        </ul>  
                    </div>
                    
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;UTTAPAM</li> 

                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ONION UTTAPAM</li> 

                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PESARATU </li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ONION PESARATU</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="live special-live-items" src="https://kbkevents.in/images/menu/live-items.png" className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-12" ></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Special Live Chat</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PANI PURI </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SAMOSA RAGADA</li>                             
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KACHORI RAGADA </li>                             
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CUTLET RAGADA</li>
                        </ul>
                    </div>                                
                    
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DHAI PURI</li> 

                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BHEL PURI </li> 

                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DHAI PAPADI </li>
                 
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PAV BHAJI</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img alt='img' loading="lazy" src="https://kbkevents.in/images/menu/special-live-items.png" className="img-fluid"/>
                    </div>
                </div>


                         
            </div>
        </div>

    </div>

</div>
</div>
    </div>
  )
}
