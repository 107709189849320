import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="banner-contact">

        <div className="container">

            <div className="banner-content">

                <h2>Pricing Policy</h2>

            </div>

        </div>

        </div>



        <div className="contact">

        <div className="container">

        <div className="row my-5">
        <h1 className='text-center bold'>Pricing Policy for KBK Events</h1>


<p>At <strong>KBK Events</strong>, we understand that each event is unique, and pricing can vary based on the specific needs and requirements of our clients. Our goal is to provide transparent and competitive pricing while delivering exceptional value and service quality. Below is an outline of our pricing policy:</p>

<h2 class="mt-3">1. Initial Consultation</h2>
<ul>
    <li><strong>Free of Charge</strong></li>
    <li>Discuss your vision, requirements, and budget.</li>
    <li>Provide a preliminary proposal and cost estimate.</li>
</ul>

<h2 class="mt-3">2. Event Planning and Management</h2>
<ul>
    <li><strong>Basic Package</strong></li>
    <ul>
        <li>Includes consultation, event theme planning, vendor coordination, and on-site management.</li>
    </ul>
    <li><strong>Standard Package</strong></li>
    <ul>
        <li>Includes everything in the Basic Package plus:</li>
        <li>Venue selection and booking</li>
        <li>Invitation design and distribution</li>
        <li>Coordination with caterers, decorators, and entertainment providers</li>
    </ul>
    <li><strong>Premium Package</strong></li>
    <ul>
        <li>Includes everything in the Standard Package plus:</li>
        <li>Customized event design and décor</li>
        <li>Full-service catering management</li>
        <li>Comprehensive audio-visual setup</li>
        <li>Event day coordination with a dedicated team</li>
    </ul>
</ul>

<h2 class="mt-3">3. Wedding Planning</h2>
<ul>
    <li><strong>Basic Wedding Package</strong></li>
    <ul>
        <li>Includes venue booking, basic décor, vendor coordination, and on-site management.</li>
    </ul>
    <li><strong>Standard Wedding Package</strong></li>
    <ul>
        <li>Includes everything in the Basic Wedding Package plus:</li>
        <li>Detailed wedding planning and timeline creation</li>
        <li>Coordination of pre-wedding events</li>
        <li>Professional photography and videography</li>
    </ul>
    <li><strong>Luxury Wedding Package</strong></li>
    <ul>
        <li>Includes everything in the Standard Wedding Package plus:</li>
        <li>Exclusive venue selection</li>
        <li>High-end décor and design</li>
        <li>Premium catering and bar services</li>
        <li>Live entertainment and performances</li>
    </ul>
</ul>

<h2 class="mt-3">4. Corporate Events</h2>
<ul>
    <li><strong>Corporate Meeting Package</strong></li>
    <ul>
        <li>Includes venue booking, basic audio-visual setup, and refreshment arrangements.</li>
    </ul>
    <li><strong>Corporate Conference Package</strong></li>
    <ul>
        <li>Includes everything in the Corporate Meeting Package plus:</li>
        <li>Detailed event planning and agenda creation</li>
        <li>Coordination with speakers and sponsors</li>
        <li>Comprehensive event management and support staff</li>
    </ul>
</ul>

<h2 class="mt-3">5. Add-On Services</h2>
<ul>
    <li><strong>Custom Décor and Theme Design</strong></li>
    <li><strong>Entertainment and Performances</strong></li>
    <li><strong>Photography and Videography</strong></li>
    <li><strong>Catering and Bar Services</strong></li>
    <ul>
        <li>Custom pricing based on menu selection</li>
    </ul>
</ul>

<h2 class="mt-3">6. Payment Terms</h2>
<ul>
    <li><strong>Deposit</strong></li>
    <ul>
        <li>A 50% non-refundable deposit is required upon signing the contract to secure your date and services.</li>
    </ul>
    <li><strong>Balance Payment</strong></li>
    <ul>
        <li>The remaining balance is due 7 days prior to the event.</li>
    </ul>
    <li><strong>Additional Charges</strong></li>
    <ul>
        <li>Any additional services or changes requested after the initial agreement may incur additional charges.</li>
    </ul>
</ul>

<h2 class="mt-3">7. Cancellation Policy</h2>
<ul>
    <li><strong>Up to 30 Days Before Event</strong></li>
    <ul>
        <li>50% refund of the total amount paid.</li>
    </ul>
    <li><strong>Less than 30 Days Before Event</strong></li>
    <ul>
        <li>No refund available.</li>
    </ul>
</ul>

<p>Our team at <strong>KBK Events</strong> is dedicated to providing flexible and customizable options to suit your needs. For a detailed quote and personalized consultation, please contact us directly. Let us help you create an event that exceeds your expectations!</p>
        </div>

        </div>

        </div>
    </div>
  )
}
