import React from "react";
export default function About() {
    return (
        <div>
            <div className="banner-ab">
                <div className="container">
                    <div className="banner-content">
                        <h2>About Us</h2>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="content">
                                <h2>Who we are</h2>

                                <p className="justify">
                                    KBK Events is one of Hyderabad's leading event management companies. We have a team of experienced professionals who can handle any type of event, no matter how large or complex. Our mission is to provide
                                    quality services that exceed our client's expectations. Every party has a different theme, and we work hard to make each event successful.
                                </p>
                                <p className="justify">
                                    KBK Events is one of the leading wedding planners in Hyderabad. KBK Events is a premier event planning & management firm specializing in creating unforgettable client experiences. From creative concept to
                                    exceptional execution, we provide the highest quality services tailored to each individual's needs. Let us make your special occasion stand out from the rest!
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <img src="https://kbkevents.in/images/about-us.jpg" className="img-fluid" alt="KBK Events Hyderabad" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-2">
                <div className="container">
                    <div className="row mob-cr">
                        <div className="col-lg-6">
                            <img src="https://kbkevents.in/images/about1.jpg" className="img-fluid" alt="KBK Events Hyderabad" />
                        </div>

                        <div className="col-lg-6">
                            <div className="content">
                                <h2>What we can do</h2>

                                <p className="justify">
                                    KBK Events is an organization that provides people with fun and engaging activities to make their events truly special. From outdoor skydiving adventures to movie marathons and birthday bashes, we’ve got
                                    something for everybody! Whether you are planning a formal corporate event or a backyard barbeque, we have the resources and experience to help you make it one to remember. <br />
                                    <br />
                                    We customize all kinds of activities according to your needs and provide professional insights for those little details which can help create perfect memories for you to cherish forever. No matter the
                                    occasion, KBK Events has something special in store that will leave everyone wanting more!!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-3">
                <h2>Four Reasons Why You Should Choose Us</h2>
            </div>
            <div className="frendlyteam-section">
                <div className="row section-4">
                    <div className="col-lg-6 ">
                        <div className="icons">
                            <img src="https://kbkevents.in/images/About/icon-1.webp" alt="KBK Events Hyderabad" />
                        </div>

                        <div className="content-fr">
                            <h2>Friendly Team</h2>

                            <p className="justify">
                                KBK Events is an exceptional team dedicated to creating memorable experiences and lasting impressions. Our goal is to meet the individual needs of our clients by designing innovative events that exceed
                                expectations. From small private soirees, to large-scale corporate gatherings – we bring your vision into reality with unparalleled expertise in all aspects of event planning!
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <img src="https://kbkevents.in/images/friendly-team.jpg" className="img-fluid" alt="KBK Events Hyderabad" />
                    </div>
                </div>
            </div>
            <div className="frendlyteam-section">
                <div className="row section-4">
                    <div className="col-lg-6">
                        <img src="https://kbkevents.in/images/About/img4.jpg" className="img-fluid" alt="KBK Events Hyderabad" />
                    </div>

                    <div className="col-lg-6 ">
                        <div className="icons">
                            <img src="https://kbkevents.in/images/About/icon-2.jpg" alt="KBK Events Hyderabad" />
                        </div>

                        <div className="content-fr">
                            <h2>Unforgettable Time</h2>

                            <p className="justify">
                                KBK Events offers high-caliber event planning services that add a perfect touch of sophistication and style to any special occasion. Whether it's a corporate conference or an intimate gathering, our team
                                ensures every detail is flawlessly coordinated for your complete satisfaction. Let us help you create unforgettable memories at your next event!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="frendlyteam-section">
                <div className="row section-4">
                    <div className="col-lg-6 ">
                        <div className="icons">
                            <img src="https://kbkevents.in/images/About/icon-3.png" alt="KBK Events Hyderabad" />
                        </div>

                        <div className="content-fr">
                            <h2>Unique Scenarios</h2>

                            <p className="justify">
                                KBK Events is a unique event planning and hosting company specializing in providing unique scenarios for different events. From birthdays to anniversaries, engagements to corporate functions, they can curate
                                a situation that will be sure to entertain all in attendance.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <img src="https://kbkevents.in/images/unique-scenarios.jpg" className="img-fluid" alt="KBK Events Hyderabad" />
                    </div>
                </div>
            </div>
            <div className="frendlyteam-section">
                <div className="row section-4">
                    <div className="col-lg-6">
                        <img src="https://kbkevents.in/images/perfect-venues.jpg" className="img-fluid" alt="KBK Events Hyderabad" />
                    </div>

                    <div className="col-lg-6 ">
                        <div className="icons">
                            <img src="https://kbkevents.in/images/About/icon-4.jpg" alt="KBK Events Hyderabad" />
                        </div>

                        <div className="content-fr">
                            <h2>Perfect Venues</h2>

                            <p className="justify">
                                KBK Events provides perfect venues for all your special occasions. No matter the occasion, our experienced professionals can work with you to make your dreams a reality. We provide stunning venues that are
                                sure to impress and delight your guests. Our event spaces are designed to be both elegant and spacious, providing plenty of room for large events as well as small gatherings.
                                <br /> <br />
                                On top of all this, we offer unbeatable prices and amazing packages that include everything needed for your special event. We have something suitable for everyone and will strive to customize a plan that fits
                                within your budget as well as meets all of your needs. Contact us today to learn more about how KBK Events can help create the perfect venues for your next memorable occasion!
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="budget-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="budget-content">
                                <h2>Looking for something extraordinary?</h2>

                                <p>PLAN YOUR BUDGET AND LET'S GET STARTED!</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <a href="tel:+91 93921 21212">
                                <button type="button" className="btn-budget ">
                                    CALL US NOW
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
