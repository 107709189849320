import React from 'react'

export default function Catering() {
  return (
    <div>
       <div className="banner">
            <div className="container">
                <div className="banner-content">
                    <h2>catering service Hyderabad </h2>
                </div>
            </div>
        </div>
        <div className="room-grid-area pt-95 pb-100">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="section-title title-2 mb-50">
                   <span>We Are “KBK Events”</span>
                    <h3>Our Caters</h3>

                    <p className="justify-content">
                    KBK Caterers provide catering services in Hyderabad, catering to various occasions, from corporate events and weddings to birthday parties and more. With years of experience in the catering business, they ensure each meal is prepared with quality ingredients and the utmost attention to detail.
                    <br />
                    Also, their experienced staff makes service smooth and efficient while providing a five-star catering experience. On top of that, customers have praised kbk Caterers for their customizable catering menu ranging from vegetarian snacks to tasty desserts; they tailor menus specifically for individual needs.
                    <br/>Without a doubt, KBK Catering Services is your destination for any catering needs in Hyderabad.

                    </p>
                    <a href="contactus"><button className="btn btn-danger pl-5 pr-5 my-3">Know more</button></a>
                </div>
            </div>
            <div className="col-md-6">
                <img loading="lazy" src="https://kbkevents.in/images/catering1.jpg" alt="KBK Events Hyderabad" title="" className="img-fluid rounded" />
            </div>
        </div>

        <div className="row my-5 mob-cr">
            <div className="col-md-6">
                <img loading="lazy" src="https://kbkevents.in/images/catering3.jpg" alt="KBK Events Hyderabad" title="" className="img-fluid rounded" />
            </div>
            <div className="col-md-6">
                <div className="section-title title-2 mb-50">

                    <p className="justify-content">
                    KBK Caterers is a marriage catering service based in India. With a specialized marriage planning team, they provide services for everything from wedding design to cake cutting for marriage events all over the country. KBK Caterers have an amazing record of making each marriage event unique and special due to their strong attention to detail and forward-thinking approach to marriage planning.
                    <br /><br />
                    Their menu options are endless, with many traditional and modern Indian dishes included, and they offer custom designed menus with both vegetarian and non-vegetarian options available.
                    <br /><br />
                    KBK Caterers can manage any size marriage event, regardless of budget or number of guests. Their aim is to leave couples with an unforgettable marriage experience using their professional services, delicious food, and ability to create a romantic setting that will be cherished for years to come.

                    </p>
                    <a href="contactus"><button className="btn btn-danger pl-5 pr-5 my-3">Know more</button></a>
                </div>
            </div>
        </div>

        <div className="row my-5">
            <div className="col-md-6">
                <div className="section-title title-2 mb-50">

                    <p className="justify-content">
                    KBK Events Caterers offer small party catering in Hyderabad and nearby areas. Whether it’s a small intimate gathering or a large celebration, their team of experienced caterers can manage all types of events to make them an outstanding success.
                    <br/><br/>Equipped with the best quality ingredients, KBK Events Catering serves authentic and delicious food at your small get-togethers, perfectly crafted to the tastes of each of your guests.
                    <br/>Their small party catering services are available at highly competitive prices and prepared with utmost attention to detail. With years of experience in perfecting small occasion banquets, KBK Events Caterers is your top choice for small parties.
                    </p>

                    <a href="contactus"><button className="btn btn-danger pl-5 pr-5 my-3">Know more</button></a>
                </div>
            </div>
            <div className="col-md-6">
                <img loading="lazy" src="https://kbkevents.in/images/catering2.jpg" alt="KBK Events Hyderabad" title="" className="img-fluid rounded" />
            </div>
        </div>
        
    </div>
</div>

    </div>
  )
}
