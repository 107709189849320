import React from 'react'

export default function Event() {
  return (
    <div>
       <div className="banner-events">
            <div className="container">
                <div className="banner-content">
                    <h2>KBK Events</h2>
                </div>
            </div>
        </div>
        <div className="room-grid-area pt-95 pb-100">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="section-title title-2 mb-50">
                    <h3>Our KBK Events</h3>

                    <p className="justify-content">
                        KBK events is the perfect place to enjoy an unforgettable evening. We bring together family, friends and acquaintances from worldwide and create an energetic, vibrant atmosphere. Our values are centered around
                        providing excellent customer service, paying attention to detail, offering quality entertainment and producing experiences that will be cherished for a lifetime.<br />
                        <br />
                        We deliver a service that creates memories, promoting a friendly relaxed atmosphere filled with laughter and smiles. Every event is tailored to you, ensuring that it fits perfectly within your time frame, budget and
                        desired outcome. Let KBK events give you an experience that you’d be glad to recall over years to come!
                    </p>
                    <a href="contactus"><button className="btn btn-danger pl-5 pr-5 my-3">Know more</button></a>
                </div>
            </div>
            <div className="col-md-6">
                <img loading="lazy" src="https://kbkevents.in/images/service1.jpg" alt="KBK Events Hyderabad" title="" className="img-fluid rounded" />
            </div>
        </div>

        <div className="row my-5 mob-cr">
            <div className="col-md-6">
                <img loading="lazy" src="https://kbkevents.in/images/our-events.jpg" alt="KBK Events Hyderabad" title="" className="img-fluid rounded" />
            </div>
            <div className="col-md-6">
                <div className="section-title title-2 mb-50">

                    <p className="justify-content">
                        KBK Events has become one of the most well-known Events Organisers in Hyderabad. They are a professional Events Management Company in Hyderabad that provide exceptional customer service while creating extraordinary
                        events that inspire and connect people. <br />
                        <br />
                        KBK Events have achieved excellence in the event planning industry and they specialize in end-to-end event management services, from concept creation to post-event analysis. Whether it's Weddings, Corporate Events or
                        Theme Parties, they provide their clients with unique experiences and lasting memories that will never be forgotten. From conception to execution, no detail is spared when it comes to bringing their client’s visions
                        to life!
                    </p>
                    <a href="contactus"><button className="btn btn-danger pl-5 pr-5 my-3">Know more</button></a>
                </div>
            </div>
        </div>

        <div className="row my-5">
            <div className="col-md-6">
                <div className="section-title title-2 mb-50">

                    <p className="justify-content">
                        KBK events is an event planner in Hyderabad that provides a comprehensive range of event planning services. From weddings to corporate events and beyond, they have the knowledge and expertise to help you bring your
                        vision to life. Their team of event planners are committed to working with you every step of the way, ensuring that each event is as successful, memorable, and stress-free as possible.<br />
                        They can be relied upon to provide attentive customer service while developing creative solutions and finding ways to lower costs so that their clients can maximize their event experience. When you need an event
                        planner in Hyderabad, KBK Events has covered you!
                    </p>

                    <a href="contactus"><button className="btn btn-danger pl-5 pr-5 my-3">Know more</button></a>
                </div>
            </div>
            <div className="col-md-6">
                <img loading="lazy" src="https://kbkevents.in/images/service3.jpg" alt="KBK Events Hyderabad" title="" className="img-fluid rounded" />
            </div>
        </div>
        <div className="row">
            <div className="col-md-3 text-align-center align-items-center">
                <div className="border bg-light p-4 rounded text-center mb-1">
                    <i className="fa fa-envelope-o h1 text-primary"></i>
                    <h5>Get quotes from top event planners</h5>
                </div>
            </div>
            <div className="col-md-3 text-align-center align-items-center">
                <div className="border bg-light p-4 rounded text-center mb-1">
                    <i className="fa fa-calendar-check-o h1 text-warning"></i>
                    <h5>The easiest way to plan your next event</h5>
                </div>
            </div>
            <div className="col-md-3 text-align-center align-items-center">
                <div className="border bg-light p-4 rounded text-center mb-1">
                    <i className="fa fa-clock-o h1 text-danger"></i>
                    <h5>Find the perfect venue in minutes</h5>
                </div>
            </div>
            <div className="col-md-3 text-align-center align-items-center">
                <div className="border bg-light p-4 rounded text-center mb-1">
                    <i className="fa fa-smile-o h1 text-success"></i>
                    <h5>We can help make your event a success</h5>
                </div>
            </div>
        </div>
    </div>
</div>

    </div>
  )
}
