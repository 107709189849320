import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function OurServies() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const imageStyle = {
    width: '449.667px',
    height: '500px',
    objectFit: 'cover',
  };

  return (
    <div className="our-favorite-room-area pb-95">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center">
              <span>We Are “KBK EVENTS”</span>
              <h2>Making Events Easy!</h2>
              <p className="text-center mb-30">
                We believe that events should be easy and stress-free. That's why we're the most trusted event management company. Let us take care of everything for you!
              </p>
            </div>
          </div>
        </div>

            
      </div>
      <div className='container'>
      <div className='row our-event-services'>
        <Slider {...settings}>
                <div className="slide-item">
                  <img src="https://kbkevents.in/images/reception1.webp" alt="Slide 1" style={imageStyle} />
                  <div className="slide-caption">
                    <div className='categories__content'>
                    <h6><a href="/event">Reception</a></h6>
                    <p>If you're looking for a magical evening of celebration, KBK Events Reception is the place to go. From delightful decor and delectable cuisine to great music and wonderful company.</p>
                    </div>
                    
                  </div>
                </div>
                <div className="slide-item">
                  <img src="https://kbkevents.in/images/sangeet1.webp" alt="Slide 2" style={imageStyle} />
                  <div className="slide-caption">
                  <h6><a href="/event"> Sangeet</a></h6>
                  <p>KBK Events is your one-stop shop for unforgettable musical experiences! Sangeeth presents a night of incredible live performances that always make it an evening to remember.</p>
                  </div>
                </div>
                <div className="slide-item">
                  <img src="https://kbkevents.in/images/engegement1.webp" alt="Slide 3" style={imageStyle} />
                  <div className="slide-caption">
                  <h6><a href="/event"> Engagement</a></h6>
                  <p>KBK Events Engagement is the ideal way to make your special day stand out. With a wide range of services ranging from wedding planning and coordination to venue support and vendor management.</p>
                  </div>
                </div>
                <div className="slide-item">
                  <img src="https://kbkevents.in/images/wedding1.webp" alt="Slide 3" style={imageStyle} />
                  <div className="slide-caption">
                  <h6><a href="/event">Wedding</a></h6>
                  <p>KBK Events specialises in enchanting wedding receptions! They coordinate all of your special day needs, from the ideal venue to delectable catering, and will assist you in making your dream a reality.</p>
                  </div>
                </div>
                <div className="slide-item">
                  <img src="https://kbkevents.in/images/mehandi.webp" alt="Slide 3" style={imageStyle} />
                  <div className="slide-caption">
                  <h6><a href="/event">MEHENDI</a></h6>
                  <p>KBK Events Mehindi is a memorable experience! Whether you want unique designs or a simple pattern, their team will collaborate with you every step of the way to ensure your henna design stands out.</p>
                  </div>
                </div>
                
        </Slider>
      </div>
      </div>
      
    </div>
  );
}
