import React from 'react'
import{Link} from 'react-router-dom';

export default function OurEventsServices() {
  return (
    <div>
    <div className="section-title text-center">
        <span>We Are “KBK Events”</span>

        <h3>Our Events Services</h3>

        <p className="text-center mb-30">
            KBK Events Services is your one-stop solution as we provide service to them. for all of your special event needs. From memorable weddings to corporate conventions, we help you make lasting memories that will
            stay with you forever!
        </p>
    </div>
    
         <div className="row our-event-services align-items-center">
         <div className='col-md-6'>
         <div id="carouselExample1" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
            <img src="https://kbkevents.in/images/service3.jpg" className="d-block w-100" alt="img" />
            </div>
            <div className="carousel-item">
            <img src="https://kbkevents.in/images/service4.avif" className="d-block w-100" alt="img" />
            </div>
            <div className="carousel-item">
            <img src="https://kbkevents.in/images/service1.jpg" className="d-block w-100" alt="img" />
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample1" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample1" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
        </div>
        </div>
        <div className="col-md-6 events-list">
            <div className="row">
                <div className="col-md-4 col-6 col-xs-6">
                    <div className="figeres">
                        <Link to="/event" className="food">
                            <img loading="lazy" src="https://kbkevents.in/images/event1.png" alt="Kbk Events" />

                            <figcaption>Wedding</figcaption>
                            </Link>
                    </div>
                </div>

                <div className="col-md-4 col-6 col-xs-6">
                    <div className="figeres">
                        <Link to="/event" className="food">
                            <img loading="lazy" src="https://kbkevents.in/images/event2.png" alt="Kbk Events" />

                            <figcaption>RECEPTION</figcaption>
                       </Link>
                    </div>
                </div>

                <div className="col-md-4 col-6 col-xs-6">
                    <div className="figeres">
                        <Link to="/event" className="food">
                            <img loading="lazy" src="https://kbkevents.in/images/event3.png" alt="Kbk Events" />

                            <figcaption>SANGEET</figcaption>
                       </Link>
                    </div>
                </div>
            

            
                <div className="col-md-4 col-6 col-xs-6">
                    <div className="figeres">
                        <Link to="/event" className="food">
                            <img loading="lazy" src="https://kbkevents.in/images/event5.png"  alt="KBK Events" />

                            <figcaption>ENGAGEMENT</figcaption>
                       </Link>
                    </div>
                </div>

                <div className="col-md-4 col-6 col-xs-6">
                    <div className="figeres">
                        <Link to="/event" className="food">
                            <img loading="lazy" src="https://kbkevents.in/images/event7.png" alt="KBK Events"  />

                            <figcaption>MEHENDI</figcaption>
                       </Link>
                    </div>
                </div>

                <div className="col-md-4 col-6 col-xs-6">
                    <div className="figeres">
                        <Link to="/event" className="food">
                            <img loading="lazy" src="https://kbkevents.in/images/event8.png"  alt="KBK Events" />

                            <figcaption>CORPORATE</figcaption>
                       </Link>
                    </div>
                </div>
            
                <div className="col-md-4 col-6 col-xs-6">
                    <div className="figeres">
                        <Link to="/event" className="food">
                            <img loading="lazy" src="https://kbkevents.in/images/event10.png"  alt="KBK Events" />

                            <figcaption>BIRTHDAY</figcaption>
                       </Link>
                    </div>
                </div>

                <div className="col-md-4 col-6 col-xs-6">
                    <div className="figeres">
                        <Link to="/event" className="food">
                            <img loading="lazy" src="https://kbkevents.in/images/event11.png"  alt="KBK Events" />

                            <figcaption>ANNIVERSARY</figcaption>
                       </Link>
                    </div>
                </div>

                <div className="col-md-4 col-6 col-xs-6">
                    <div className="figeres">
                        <Link to="/event" className="food">
                            <img loading="lazy" src="https://kbkevents.in/images/event12.png"  alt="KBK Events" />

                            <figcaption>COCKTAIL PARTY</figcaption>
                       </Link>
                    </div>
                </div>
            </div>
        </div>
         </div>
    </div>
  )
}
