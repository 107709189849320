import React from 'react'

export default function ContactUs() {
  return (
    <div>
        <div class="banner-contact">

            <div class="container">

                <div class="banner-content">

                    <h2>Contact Us</h2>

                </div>

            </div>

        </div>
        <div class="contact">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="box">
                            <i class="fa fa-envelope" aria-hidden="true"></i>

                            <h2>kbkevents.in@gmail.com</h2>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="box">
                            <i class="fa fa-phone" aria-hidden="true"></i>

                            <h2>+91 93921 21212</h2>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="box">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>

                            <h6 class="text-white">H-No:2-1-8 NR, Suite 1A, Saraswathi Colony, Uppal, Hyderabad</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contactus-section">

                            <div class="container">

                                <div class="row">

                                    <div class="col-lg-5 contact-content" >

                                        <h2>Contact Us</h2>

                                        <p>KBK Events is the leading event management and decoration company in South India. Our goal is to provide the best possible experience for our customers while staying within their budget. Contact us today to discuss your event needs!</p>

                                        <h3>Office Address:</h3>

                                        <p>H-No:2-1-8 NR, Suite 1A, Saraswathi Colony, Uppal, Hyderabad, Telangana, India - 500039</p>

                                    </div>

                                    <div class="col-lg-7  contact-form">

                                        

                                      <form id="contactForm" method="post" action="contactForm.php"  onsubmit="return validateForm()">

									  <div class="row">
									     

										<div class="col-lg-6">

											 <label for="name" class="form-label"> Name <spam>*</spam></label>

											 <input type="text" minlength="3" maxlength="25" onkeyup="this.value = this.value.replace(/[^a-zA-Z\s]/g, '')" id="name" name="name" class="form-control" placeholder="John" required />

										</div>

										<div class="col-lg-6">

											<label for="phone" class="form-label"> Phone Number <spam>*</spam></label>

											<input type="tel" onkeypress="return onlyNumberKey(event)"  minlenght="10" maxlength="13" id="phone" name="phone" class="form-control" required/>

										</div>

										<div class="col-lg-12">

											<label for="email" class="form-label"> Email Address <spam>*</spam></label>

											<input type="email" id="email" name="email" placeholder="email@domain.com" class="form-control" required/>

										</div>

										<div class="col-lg-12">

											<label for="message" class="form-label"> Message</label>

											<textarea  class="form-control" name="message" minlength="10" maxlenght="200" placeholder="Message" id="message" rows="3" required></textarea>

										</div>
										<div class="col-md-12">
										    
										    <div class="g-recaptcha" data-callback="recaptchaCallback" data-sitekey="6LfH9UEkAAAAANUXZJDmFsL2rszAaOgarXHYrHaR"></div>
										</div>
										

										<div class="col-lg-12 mt-4">

											<button type="submit" name="submit22" class="btn-contactus ">Send</button>

										</div>

									  </div>

  



                                    </form>

                                </div>

                            </div>

                        </div>

                        

						</div>
                        <div class="map-section">

                            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243647.31698248573!2d78.26795853991645!3d17.4122998009489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa1e9711913bd918d%3A0x70545102a600ea40!2sKBK%20Events%20Catering%20Service%20in%20Hyderabad!5e0!3m2!1sen!2sin!4v1679112870043!5m2!1sen!2sin" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>

      
</div>

  )
}
