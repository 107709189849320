import React from 'react'

export default function NonVegFood() {
  return (
    <div>
      <div className="veg-menu-banner">

<div className="container">

    <div className="banner-content">

        <h2>NON VEG MENU</h2>
        <h6 className="banner-text1 text-white p-2"><i className="fa fa-home"></i>Home <i className="fa fa-chevron-right"></i> <span className="text-danger">Standard Non Veg Menu</span></h6>

    </div>

</div>

</div>

<div className="section menu-section">

<div className="container menu-item">

    <div className="row menu11">

        <div className="col-lg-3" id="sidebar">
            <div className="sidebar-content">
                <h2 className="h1 sign">Standard</h2>
                <h2 className="h1 sign">Non Veg</h2>
                <h2 className="h1 sign">Menu</h2>
                <p className="">By: KBK Events</p>
            </div>
            
        </div>

        <div className="col-lg-9">
            <h2 className="text-white">Exclusive Non-Vegetarian Menu</h2>
            <div className="menu-details">
                
                <div id="meneee" className="row my-3">
                    <div className="col-lg-12">
                        <h4>Menu “A” @ 550+ TAXES </h4>
                    </div>
                    <div className="col-md-4">                                    
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 WELCOME DRINK</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VARIETIES OF ROTI</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 DRY VEG FRY</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VEG CURRIES</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 DAL</li>
                                                                   
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group"> 
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 MUTTON CURRY</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 CHICKEN CURRY </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 BAGARA RICE</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 SWEET</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 ICE CREAM</li>                                        
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/non-veg-p3.png" className="img-fluid"/>
                    </div>
                </div>
                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Menu “B” @ 600+ TAXES </h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 WELCOME DRINK</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 SALADS</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VEG STARTER </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VARIETIES OF ROTI</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  VEG CURRIES</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 CHICKEN CURRY</li>
                            
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 NON VEG VARIETY RICE </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  DAL  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  SWEET  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1  ICE CREAM  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 LIVE ITEM</li>
                            
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/non-veg-p2.png" className="img-fluid"/>
                    </div>
                    <div className="col-md-12">
                        <h6 className="text-white my-2 ml-3">Note: MINIMUM 100 PACKS</h6>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Menu “C” @ 650+ TAXES</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; WELCOME DRIN</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 SALAD</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VEG 1 NON VEG STARTER</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VARIETIES OF ROTI</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 VEG CURRIES</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 DAL</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;2 CHICKEN CURRIES</li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 NON VEG VARIETY OF RICE</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 VEG VARIETY OF RICE</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 SWEET</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 ICE CREAM</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 LIVE ITEM</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;1 SPL. LIVE ITEM</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/non-veg-p1.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Welcome Drink</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;FRESH FRUIT JUICE </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PINEAPPLE, GRAPE/</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SEASONAL FRUITS</li>                                        
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;WATER MELON, MIXED JUICE)</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ASSORTED FRUIT JUICES</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/w-drink.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Soup</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TOMATO </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CREAM OF VEG </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG HOT &amp; SOUR  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG CORN </li>                                    
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG MANCHOW </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TOMATO DHANIA SHORBA </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL SHORBA </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/soup.png" className="img-fluid"/>
                    </div>
                </div>

               
                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Salad & Chat</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GREEN SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RUSSIAN SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SPROUTED SALAD </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PASTA SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;YOGUT SALAD  </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RAJMA SALAD </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KACHUMBER SALAD</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHANNA CHAAT  </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO CHANA CHAAT </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PAPADI CHAAT </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/salad.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Veg Starter</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG MANCHURIAN</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GOBI MANCHURIAN</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GOBI 65</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CRISPY VEGETABLES</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SHANGHAI ROLLS</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SPRING ROLLS</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CRISPY POTATO</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO BAJJI</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">

                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MIRCHI BAJJI </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PLANTAIN BAJJI</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ONION PAKODA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PALAK PAKODA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CABBAGE PAKODA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG BONDA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MASALA VADA</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/veg-manchu.png" className="img-fluid"/>
                    </div>
                </div>

                

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Rotie</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TANDOORI ROTI </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PLAIN NAAN</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BUTTER NAAN</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RUMALI ROTI</li>
                                                                      
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PAROTA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PULKA</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO PAROTA </li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG PAROTA</li>
                            
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/roti.png" className="img-fluid"/>
                    </div>
                </div>

                

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Dry Veg Curry</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">   
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO FRY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KANDA FRY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DONDA FRY </li>  
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BAIGAN FRY</li>
                                  
                            
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PLANAIN FRY  </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BHENDI FRY </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CABBAGE FRY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GOBI FRY </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/dry-veg.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Veg Curry</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                          
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MALAI KOFTA</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO PALAK</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MIX VEG CURRY</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG KURMA</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG DO PYAZA</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALOO GOBI MUTTER</li>
                                                         
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BAGAR BAIGAN</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KADAI VEGETABLES</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ALLO MUTTER</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;METHI MALAI CURRY</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;NAVARTAN KURMA</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;HARIYALI METHI CHAMAN</li>             
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/vg-starters1.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Daal</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL FRY</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL MAKHANI</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;RAJMA DAL</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL MAHARANI</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL MASKA</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL TADKA</li>
                           
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;YELLOW DAL</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL PANCHAMI</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL TOMATO</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GONGURA DAL</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DAL PESHAWARI</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/daal.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Chicken Curries</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHICKEN CHATPAT</li>                             
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MURG METHI MALAI</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHICKEN NAWABI</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHICKEN MASALA</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BUTTER CHICKEN</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KADAI CHICKEN</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHICKEN DO PYAZA</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GINGER CHICKEN</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; ANDHRA CHICKEN </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; GONGURA CHICKEN </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; CHICKEN KURMA </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHICKEN CURRY HOME STYLE</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; NILGIRI CHICKEN  </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; SINGARENI CHICKEN  </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; MUNAKAYA CHICKEN </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; GREEN CHICKEN</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/chicken-curry.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Mutton Curries</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                    
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTON SAGWALA </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTON SHAI KURMA </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTON ROGAN JOSH </li>                                  
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTON MASALA </li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ACHAHRI GOSHT </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; KADAI GOSH  </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTON DO PYAZA </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTON PASANDA</li>
                                                       
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                                <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTON AFGHANI</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GONGURA MUTTON</li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTON KURMA</li>                                          
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ANDHRAMUTTONCURRY</li>                                          
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TELANGANA MUTTON</li>                                          
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MUTTON IGURU</li>                                          
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MIRIYALA MAMSUM</li>       
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/mutton-curry.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Veg Rice & Noodles</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG BAGARA RICE </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BIRYANI </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG FIRED RICE </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG CORN </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PULAV </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG PULAV</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GREEN PEAS PULAV</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;JEERA RICE</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SEZWAN FRIED RICE</li> 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG SOFT NOODLES</li>                                      
                      </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG SEZWAN NOODLES</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG FRIED RICE </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG CORN PULAV </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG PULAV</li> 
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GREEN PEAS PULAV </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;JEERA RICE </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG DUM BIRYANI  </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG SOFT NOODLES</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VEG SWZWAN NOODLES </li> 
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/veg-rice.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Non Veg Rice & Noodles</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;HYDERABADI CHICKEN DUMB </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;HYDERABADI MUTTON </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ANDHRA CHICKEN</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHICKEN SOFT</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SCHEZWAN CHICKEN</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MIXED SOFT</li>                                                               
                      </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;NON-VEG STARTERS</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHILLI CHICKEN FISH</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SCHEWAN CHICKEN</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MIXED SOFT NOODLES</li>                                        
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/hyderabadi-dum-chicken-biryani.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Non Veg Starters</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHILLI CHICKEN FISH</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHICKEN 65 TIKKA  </li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PEPPER CHICKEN </li>                                                      
                      </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;THAI PAI CHICKEN NATU</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KODI VEPUDU STICK</li>                                 
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/non-veg-starters.png" className="img-fluid"/>
                    </div>
                </div>
                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Live items</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PLAIN DOSA </li>

                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ONION DOSA </li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MASALA DOSA</li> 
                     
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SET DOSA </li>                                          
                           
                        </ul>  
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;UTTAPAM</li> 

                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ONION UTTAPAM</li> 

                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PESARATU </li>
                               <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ONION PESARATU</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/live-items.png" className="img-fluid"/>
                    </div>
                </div>

                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Sweets</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp; HOT GULAB JAMUN</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ANGUUR DHANA</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DOUBLE KA MEETHA</li>                             
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;QURBANI KA MEETHA</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KALA JAMOON</li>
                           
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;GAJAR KA HALWA</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KADDU KA KHEER</li>
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BOONDI LADDU</li>
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MOONG DAL HALWA</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/sweets.png" className="img-fluid"/>
                    </div>
                </div>

                 <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Ice Cream</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;VANILLA (S.I)</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;STRAWBERRY(S.I)</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PISTA</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;MANGO (S.I)</li>
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BUTTER SCOTCH </li> 
                          
                        </ul>    
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;TUTTI FRUITY</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CHOCOLATE</li>
                              <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;ANY SPECIAL ICE CREAM</li>
                              
                              
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/ice-cream.png" className="img-fluid"/>
                    </div>
                    <div className="col-md-12">
                        <h6 className="text-white ml-3 my-2">ICE CREAM WILL BE ARRANGED ON (Extra Cost)</h6>
                    </div>
                </div>

                                   

                
                <div className="col-md-12"></div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h4>Special Live Chat</h4>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">
                          
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PANI PURI </li>
                 

                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;SAMOSA RAGADA</li> 
                 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;KACHORI RAGADA </li>

                 
                          <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;CUTLET RAGADA</li> 

                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group">                                        
                            <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DHAI PURI</li> 

                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;BHEL PURI </li> 

                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;DHAI PAPADI </li>
                 
                           <li className="list-group-item"><i className="fa text-success fa-chevron-circle-right"></i>&nbsp;PAV BAAJI</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <img loading="lazy" alt="North Indian Daal" src="https://kbkevents.in/images/menu/special-live-items.png" className="img-fluid"/>
                    </div>
                </div>


                         
            </div>
        </div>

    </div>

</div>
</div>
    </div>
  )
}
