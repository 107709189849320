import React from 'react'

export default function IndexSlider() {
  return (
    <div>
        <div id="carouselExample" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
            <img src="https://kbkevents.in/images/slider1.webp" className="d-block w-100" alt="img" />
            </div>
            <div className="carousel-item">
            <img src="https://kbkevents.in/images/slider2.webp" className="d-block w-100" alt="img" />
            </div>
            <div className="carousel-item">
            <img src="https://kbkevents.in/images/slider1.webp" className="d-block w-100" alt="img" />
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
        </div>
        <div className="booking-area">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <form action="requested_booking.php" method="post" className="search-form">
                        <div className="form-container fix">
                            <div className="waa" id="alertMessage">
                                                                        </div>
                             </div>
                            <div className="box-select">
                                <div className="select">
                                    <input name="name" id="name" minlength="3" onkeyup="this.value = this.value.replace(/[^a-zA-Z\s]/g, '')" maxlength="25" placeholder="Name" type="text" required=""/>
                                </div>

                                <div className="select">
                                    <input name="email" id="email" placeholder="email" type="Email" required=""/>
                                </div>
                                <div className="select">
                                    <input name="phone" id="phone" type="tel" minlenght="10" maxlength="13" placeholder="Phone" minlength="10" maxlenght="13" required=""/>
                                </div>
                            
                                <div className="select">
                                    <input name="message" id="message" placeholder="Message" onkeyup="this.value = this.value.replace(/[^a-zA-Z\s]/g, '')" type="text" minlength="10" maxlenght="100" required=""/>
                                    
                                </div>

                                
                            </div>

                            <button type="submit" name="submit11" className="search default-btn">Check Availability</button>
                        </form></div>
    
                </div>
            </div>
        </div>
        </div>
  )
}
