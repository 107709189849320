import React from 'react'
import{Link} from 'react-router-dom';


export default function Footer() {
  return (
    <div>
      

<div className="footer-container">

    <div className="footer-top-area black-bg pt-90 pb-50">

        <div className="container">

            <div className="row">

                <div className="col-lg-3 col-md-6 col-12">


                    <div className="single-footer-widget mb-35">

                        <div className="footer-title">

                           <h3>About Us</h3>

                       </div>

                       <p>KBK Events Services is your one-stop solution as we provide service to them. for all of your special event needs. From memorable weddings to corporate conventions, we help you make lasting memories that will stay with you forever!</p>

                      

                </div>

            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-2 col-md-6 col-6">
                <div className="single-footer-widget mb-35">
                    <div className="footer-title">
                        <h3>Quick Links</h3>
                    </div>
                    <ul className="link-widget">
                    <li className="menu12"><span className='nav-link'><a href="/about">About</a></span></li>

                    <li className="menu13"><span className='nav-link'><a href="/event">Events</a></span></li>

                    <li className="menu14"><span className='nav-link'><a href="/banquets">Banquets</a></span></li>

                    <li className="menu15"><span className='nav-link'><a href="/catering">Catering</a></span></li>
                    <li className="menu15"><span className='nav-link'><a href="/gallery">Gallery</a></span></li>
                       
                    
                        
                    </ul>
                </div>
            </div>
            
            <div className="col-lg-2 col-6 col-md-6">
                <div className="single-footer-widget mb-35">
                    <div className="footer-title">
                        <h3>&nbsp;</h3>
                    </div>
                    <ul className="link-widget">
                        <li><a href="/banquets">Veg Menu</a></li>
                        <li><a href="/non-veg-food">Non-Veg Menu</a></li>
                        <li><a href="/contact-us">Contact Us</a></li>
                        <li><a href="/terms-and-conditions">Term Of Service</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/pricing-policy">Pricing Policy</a></li>
                        <li><a href="/refund-policy">Refund Policy</a></li>
                        <li><a href="/shipping-and-delivery">Shipping and Delivery</a></li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-1"></div>

            <div className="col-lg-3 col-md-6 col-12">

                <div className="contact-info">

                        <ul>

                            <li><i className="fa fa-home"></i>&nbsp;H-No:2-1-8 NR, Suite 1A, Saraswathi Colony, Uppal, Hyderabad, Telangana, India - 500039</li>

                            <li><i className="fa fa-phone"></i> &nbsp;<a href="tel:+91 93921 21212">+91 93921 21212</a></li>

                            <li><i className="fa fa-envelope-o"></i> &nbsp;<a href="mailto:kbkevents.in@gmail.com">kbkevents.in@gmail.com</a></li>

                        </ul>

                    </div>

            <div className="footer-title">

                <h3>Stay Connected</h3>

            </div>

            <ul className="social-icons">

                <li><a href="https://www.facebook.com/kbkeventshyderabad" aria-label="Facebook" title="Facebook"><i className="fa fa-facebook"></i></a></li>

                <li><a href="https://twitter.com/kbk_events"  aria-label="twitter" title="Twitter"><i className="fa fa-twitter"></i></a></li>


                <li><a href="https://www.linkedin.com/company/kbk-events-hyderabad/"  aria-label="Linkedin" title="Linkedin"><i className="fa fa-linkedin"></i></a></li>


            </ul>

        </div>

    </div>

</div>

</div>

</div> 


<div className="footer-bottom-area">

<div className="container">

    <div className="row">

        <div className="col-md-6">

            <div className="footer-copyright">

                <p className="f-mob">Copyright © <a href="https://kbkevents.in">KBK Events</a> All Rights Reserved</p>

            </div>

        </div>

        <div className="col-md-6">

            <div className="footer-design-by text-right">

                <p>Designed by <a href="https://www.kbkbusinesssolutions.com/" aria-label="Design and Developed By" title="Design and Developed By" >KBK Business Solutions</a></p>

            </div>

        </div>

    </div>

</div>

</div> 

</div>

  )
}
