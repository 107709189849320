import './App.css';
import React from 'react';
import{BrowserRouter, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/footer/Footer';
import CounterSection from './components/index/CounterSection';
import IndexSlider from './components/index/IndexSlider';
import OurEventsServices from './components/index/OurEventsServices';
import OurGallery from './components/index/OurGallery';
import OurServies from './components/index/OurServies';
import Welcome from './components/index/Welcome';
import About from './components/about/About';
import Event from './components/events/Event';
import Banquets from './components/banquets/Banquets';
import Catering from './components/catering/Catering';
import VegFood from './components/vegfood/VegFood';
import NonVegFood from './components/nonvegfood/NonVegFood';
import Gallery from './components/gallery/Gallery';
import ContactUs from './components/contactus/ContactUs';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';
import PricingPolicy from './components/pricingpolicy/PricingPolicy';
import TermsAndConditions from './components/termsandconditions/TermsAndConditions';
import RefundPolicy from './components/refundpolicy/RefundPolicy';
import ShippingDelivery from './components/shippinganddelivery/ShippingDelivey';


function App() {
  return(
    <>
    <BrowserRouter>
      <Navbar/>
      <Routes>

        <Route path='/' element={
              <>
                
                <IndexSlider/>
                <Welcome/>
                <OurServies />
                <OurEventsServices/>
                <CounterSection />
                <OurGallery/>
              </>
            } />
          <Route path='about' element={
            <>
             <About/>
            </>
          } />
          <Route path='event' element={
            <>
             <Event/>
            </>
          } />
          <Route path='banquets' element={
            <>
             <Banquets/>
            </>
          } />
          <Route path='catering' element={
            <>
             <Catering/>
            </>
          } />
          <Route path='veg-food' element={
            <>
             <VegFood/>
            </>
          } />
          <Route path='non-veg-food' element={
            <>
             <NonVegFood/>
            </>
          } />
          <Route path='gallery' element={
            <>
              <Gallery/>
             <OurGallery/>
            </>
          } />
          <Route path='contact-us' element={
            <>
             <ContactUs/>
            </>
          } />
          <Route path='privacy-policy' element={
            <>
             <PrivacyPolicy/>
            </>
          } />
          <Route path='pricing-policy' element={
            <>
             <PricingPolicy/>
            </>
          } />
          <Route path='terms-and-conditions' element={
            <>
             <TermsAndConditions/>
            </>
          } />
          <Route path='refund-policy' element={
            <>
             <RefundPolicy/>
            </>
          } />
          <Route path='shipping-and-delivery' element={
            <>
             <ShippingDelivery/>
            </>
          } />


        </Routes>

      
      <Footer/>
    </BrowserRouter>
              
    </>
  );
}

export default App;
