import React from 'react'

export default function Banquets() {
  return (
    <div>
       <div className="banner">
            <div className="container">
                <div className="banner-content">
                    <h2>Banquet halls in Hyderabad</h2>
                </div>
            </div>
        </div>
        <div className="room-grid-area pt-95 pb-100">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="section-title title-2 mb-50">
                   <span>We Are “KBK Events”</span>
                    <h3>Our Banquets</h3>

                    <p className="justify-content">
                    KBK Events provides banquet halls of excellent quality in Hyderabad. These banquet halls have plenty of space to accommodate a large number of guests, making it suitable for various occasions such as weddings, conferences, and corporate events. The banquet hall layout is professionally managed with modern design elements, pristine furnishing and comfortable seating arrangements.<br />
                        <br />
                        The banquet halls are designed keeping in mind the preferences of the customers - all necessary details like sound system providers, food caterers or decoration artists can be found on-site with trained personnel. The team at KBK Events go out of their way to make sure their client's special occasion is beautifully managed.
                    </p>
                    <a href="contactus"><button className="btn btn-danger pl-5 pr-5 my-3">Know more</button></a>
                </div>
            </div>
            <div className="col-md-6">
                <img loading="lazy" src="https://kbkevents.in/images/banquet-halls1.jpg" alt="KBK Events Hyderabad" title="" className="img-fluid rounded" />
            </div>
        </div>

        <div className="row my-5 mob-cr">
            <div className="col-md-6">
                <img loading="lazy" src="https://kbkevents.in/images/banquet-halls2.jpg" alt="KBK Events Hyderabad" title="" className="img-fluid rounded" />
            </div>
            <div className="col-md-6">
                <div className="section-title title-2 mb-50">

                    <p className="justify-content">
                    KBK Events offers function halls in Hyderabad that are ideal for celebrating any function - whether it be an engagement, wedding, birthday or anniversary. Equipped with modern amenities, the banquet halls come complete with everything needed and prepared to exceed your expectations. <br />
                        <br />
                        Enjoy the convenience of onsite catering, beautifully decorated function rooms, plus all the necessary audio-visual equipment and sound system to ensure a perfect event. With decor inspired by classic and luxury style, it's no wonder KBK Events is one of the premier function hall providers in Hyderabad.


                    </p>
                    <a href="contactus"><button className="btn btn-danger pl-5 pr-5 my-3">Know more</button></a>
                </div>
            </div>
        </div>

        <div className="row my-5">
            <div className="col-md-6">
                <div className="section-title title-2 mb-50">

                    <p className="justify-content">
                    KBK Events is one of the top wedding hall providers in Hyderabad, offering the perfect combination of elegance, convenience and affordability. Our wedding halls have been custom designed to exceed your expectations with exceptional amenities such as air conditioning, lighting, sound systems, luxurious furniture and more.
                    <br/>To ensure your wedding reception is a grand success all our wedding halls are equipped with cutting-edge technology for events of any size. With a variety of wedding hall sizes to choose from, you can find the perfect solution for even the most extravagant wedding celebrations – from large wedding receptions to intimate gatherings.
                    <br/><br/>With comprehensive services like catering, decorating and planning at competitive prices we bring value to every wedding event. In addition to excellent facilities, we provide convenient packages that cover all of your wedding needs so you don’t have to worry about anything at all! An amazing wedding experience awaits you with KBK Events.
                    </p>

                    <a href="contactus"><button className="btn btn-danger pl-5 pr-5 my-3">Know more</button></a>
                </div>
            </div>
            <div className="col-md-6">
                <img loading="lazy" src="https://kbkevents.in/images/banquet-halls3.jpg" alt="KBK Events Hyderabad" title="" className="img-fluid rounded" />
            </div>
        </div>
        
    </div>
</div>

    </div>
  )
}
