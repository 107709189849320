import React from 'react'

export default function Welcome() {
  return (
     <div className="container py-5">
        <div className="row align-items-center">
            <div className="col-md-6">
               <h2>Welcome to KBK Events the haven of your weekend</h2>
               <p>KBK Events is one of the top event organizers in Hyderabad, India. A full-service organization with vast experience, they help clients plan and execute exceptional events from start to finish. Whether it’s a grand wedding, corporate fundraiser or anniversary party, KBK Events consistently delivers high-quality services to its customers.</p>
               <p>They utilize their expertise and passion for planning to bring your vision and events to life. Their commitment to providing superior customer service makes them stand out as the best event planners in Hyderabad. With attention paid to every detail, KBK Events guarantees that you will have a memorable experience!</p>
               <button className="default-btn">EXPLORE</button>
            </div>
            <div className="col-md-6">
                <div className="first-welcome-image">
                   <img src="https://kbkevents.in/images/home-1.webp" alt="img" className='img-fluid' />
                </div>
            </div>
        </div>
     </div>
  )
}
